import { ReactNode } from "react";
import * as Styled from "./styles";

export default function PageHeader({
  title,
  children,
  copy,
  className,
}: {
  title: string;
  children?: ReactNode;
  copy?: string | ReactNode;
  className?: string;
}) {
  return (
    <Styled.Container className={className}>
      <Styled.TitleWrapper>
        <Styled.Title>{title}</Styled.Title>
        {children}
      </Styled.TitleWrapper>
      {copy && <Styled.SubTitle>{copy}</Styled.SubTitle>}
    </Styled.Container>
  );
}
